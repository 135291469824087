<template>
  <div class="flex flex-col items-center">
    <div class="text-2xl font-bold flex justify-center pb-4">
      Reset Your Password
    </div>

    <div class="text-md font-thin flex justify-center pb-8">
      We’ll email you instructions to reset your password.
    </div>

    <div class="w-80">
      <div class="pt-5">
        <label
          for="email"
          class="block text-sm font-medium text-gray-700"
        >EMAIL</label>

        <div class="mt-1">
          <input
            type="email"
            name="email"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="john@ron.digital"
          >
        </div>
      </div>

      <div class="pt-5">
        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Reset
        </button>
      </div>
    </div>

    <div class="pt-8 flex justify-center items-center">
      <router-link
        :to="{ name: 'Login' }"
        class="font-medium text-indigo-600 hover:text-indigo-500 pl-2"
      >
        Return to login
      </router-link>
    </div>
  </div>
</template>